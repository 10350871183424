import React, { useState } from 'react';

import css from './FieldNumberInput.module.css';

const FieldNumberInput = () => {
  const [number, setNumber] = useState(1);

  const increment = () => {
    setNumber(num => num + 1);
  };

  const decrement = () => {
    if (number > 1) setNumber(num => num - 1);
  };

  const onNumberChange = event => {
    if (event.target.value >= 1) setNumber(Number(event.target.value));
  };

  return (
    <>
      <label for="numPeople">No. of people</label>{' '}
      <div className={css.numberInputWrapper}>
        <input
          onChange={onNumberChange}
          value={number}
          type="number"
          min="1"
          defaultValue="1"
          className={css.numberInput}
        />
        <div className={css.buttonsWrapper}>
          <div onClick={increment} className={css.incrementButton}>
            <ArrowIcon />
          </div>
          <div
            onClick={decrement}
            className={number > 1 ? css.decrementButton : css.inactiveDecrementButton}
          >
            <ArrowIcon />
          </div>
        </div>
      </div>
    </>
  );
};

const ArrowIcon = () => {
  return (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4.75L4.5 1.25L1 4.75" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default FieldNumberInput;
